function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import request from './request';
import Vue from 'vue';
var fApi;
var unique = 1;
import formCreate from '@form-create/element-ui';
var uniqueId = function uniqueId() {
  return ++unique;
};
export default function modalForm(formRequestPromise) {
  var _this = this;
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var h = this.$createElement;
  return new Promise(function (resolve, reject) {
    formRequestPromise.then(function (_ref) {
      var data = _ref.data;
      if (!data.config) data.config = {};
      data.config.submitBtn = false;
      data.config.resetBtn = false;
      if (!data.config.form) data.config.form = {};
      if (!data.config.formData) data.config.formData = {};
      data.config.formData = _objectSpread(_objectSpread({}, data.config.formData), config.formData);
      data.config.form.labelWidth = '105px';
      data.config.global = {
        upload: {
          props: {
            onSuccess: function onSuccess(rep, file) {
              if (rep.status === 200) {
                file.url = rep.data.src;
              }
            }
          }
        },
        frame: {
          props: {
            onLoad: function onLoad(e) {
              console.log(e, 'rep');
              e.fApi = fApi;
            }
          }
        },
        inputNumber: {
          props: {
            controls: false
          }
        }
      };
      data = Vue.observable(data);
      data.rules.forEach(function (e) {
        e.title += '：';
      });
      _this.$msgbox({
        title: data.title,
        showCancelButton: true,
        customClass: config.class || 'modal-form',
        mask: false,
        closeOnClickModal: false,
        message: h('div', {
          class: 'common-form-create',
          key: uniqueId()
        }, [h('formCreate', {
          props: {
            rule: data.rules,
            option: data.config
          },
          on: {
            mounted: function mounted($f) {
              fApi = $f;
            }
          }
        })]),
        beforeClose: function beforeClose(action, instance, done) {
          var fn = function fn() {
            setTimeout(function () {
              instance.confirmButtonLoading = false;
            }, 500);
          };
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            fApi.submit(function (formData) {
              request[data.method.toLowerCase()](data.action, formData).then(function (res) {
                done();
                _this.$message.success(res.msg || '提交成功');
                resolve(res);
              }).catch(function (err) {
                _this.$message.error(err.msg || '提交失败');
                // reject(err);
              }).finally(function () {
                fn();
              });
            }, function () {
              return fn();
            });
          } else {
            fn();
            done();
          }
        }
      });
    }).catch(function (e) {
      _this.$message.error(e.msg || '--');
    });
  });
}