import Layout from '@/layout';
var operationRouter = {
  path: '/operation',
  component: Layout,
  redirect: '/operation/setting',
  name: 'Operation',
  meta: {
    title: '设置',
    icon: 'clipboard',
    roles: ['admin']
  },
  children: [{
    path: 'setting',
    name: 'setting',
    component: function component() {
      return import('@/views/systemSetting/setting');
    },
    meta: {
      title: '系统设置',
      icon: 'clipboard'
    }
  }, {
    path: 'others',
    name: 'others',
    component: function component() {
      return import('@/views/systemSetting/others');
    },
    meta: {
      title: '通用设置',
      icon: 'clipboard'
    }
  }, {
    path: 'notification',
    name: 'notification',
    component: function component() {
      return import('@/views/systemSetting/notification');
    },
    meta: {
      title: '消息通知',
      icon: 'clipboard'
    }
  }, {
    path: 'onePass',
    name: 'onePass',
    component: function component() {
      return import('@/views/sms/smsConfig');
    },
    meta: {
      title: '一号通',
      icon: 'clipboard'
    }
  }, {
    path: 'roleManager',
    name: 'RoleManager',
    component: function component() {
      return import('@/views/systemSetting/administratorAuthority');
    },
    meta: {
      title: '管理权限',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'identityManager',
      component: function component() {
        return import('@/views/systemSetting/administratorAuthority/identityManager');
      },
      name: 'identityManager',
      meta: {
        title: '角色管理',
        icon: ''
      }
    }, {
      path: 'adminList',
      component: function component() {
        return import('@/views/systemSetting/administratorAuthority/adminList');
      },
      name: 'adminList',
      meta: {
        title: '管理员列表',
        icon: ''
      }
    }, {
      path: 'promiseRules',
      component: function component() {
        return import('@/views/systemSetting/administratorAuthority/permissionRules');
      },
      name: 'promiseRules',
      meta: {
        title: '权限规则',
        icon: ''
      }
    }]
  }, {
    path: 'systemSms',
    component: function component() {
      return import('@/views/sms');
    },
    name: 'systemSms',
    meta: {
      title: '短信设置',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'config',
      component: function component() {
        return import('@/views/sms/smsConfig');
      },
      name: 'SmsConfig',
      meta: {
        title: '短信账户',
        noCache: true
      }
    }, {
      path: 'template',
      component: function component() {
        return import('@/views/sms/smsTemplate');
      },
      name: 'SmsTemplate',
      meta: {
        title: '短信模板',
        noCache: true,
        activeMenu: "/operation/onePass"
      }
    }, {
      path: 'pay',
      component: function component() {
        return import('@/views/sms/smsPay');
      },
      name: 'SmsPay',
      meta: {
        title: '短信购买',
        noCache: true,
        activeMenu: "/operation/onePass"
      }
    }, {
      path: 'message',
      component: function component() {
        return import('@/views/sms/smsMessage');
      },
      name: 'SmsMessage',
      meta: {
        title: '短信开关',
        noCache: true
      }
    }]
  }, {
    path: 'deliverGoods',
    name: 'deliverGoods',
    alwaysShow: true,
    component: function component() {
      return import('@/views/systemSetting/deliverGoods');
    },
    meta: {
      title: '发货设置',
      roles: ['admin']
    },
    children: [{
      path: 'takeGoods',
      component: function component() {
        return import('@/views/systemSetting/deliverGoods/takeGoods');
      },
      name: 'takeGoods',
      meta: {
        title: '提货设置',
        noCache: true,
        roles: ['admin']
      },
      children: [{
        path: 'deliveryAddress',
        component: function component() {
          return import('@/views/systemSetting/deliverGoods/takeGoods/deliveryAddress');
        },
        name: 'deliveryAddress',
        meta: {
          title: '提货点',
          icon: ''
        }
      }, {
        path: 'collateOrder',
        component: function component() {
          return import('@/views/systemSetting/deliverGoods/takeGoods/collateOrder');
        },
        name: 'collateOrder',
        meta: {
          title: '核销订单',
          icon: ''
        }
      }, {
        path: 'collateUser',
        component: function component() {
          return import('@/views/systemSetting/deliverGoods/takeGoods/collateUser');
        },
        name: 'collateUser',
        meta: {
          title: '核销员',
          icon: ''
        }
      }]
    }, {
      path: 'freightSet',
      component: function component() {
        return import('@/views/systemSetting/deliverGoods/freightSet');
      },
      name: 'freightSet',
      meta: {
        title: '运费模板',
        noCache: true
      }
    }]
  }]
};
export default operationRouter; //collate