//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppMain',
  data: function data() {
    return {
      links: [
        // {
        //   title: '官网',
        //   key: '1',
        //   href: 'https://www.zgymsz.com',
        //   blankTarget: true
        // },
      ],
      copyright: ''
    };
  },
  created: function created() {
    console.log('process.env===>', process.env);
    this.copyright = 'Copyright ©' + process.env.VUE_APP_COPY_RIGHT;
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  }
};