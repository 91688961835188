import Layout from "@/layout";
var distributionRouter = {
  path: "/distribution",
  component: Layout,
  redirect: "/distribution/index",
  name: "Distribution",
  meta: {
    title: "分销",
    icon: "clipboard"
  },
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/distribution/index");
    },
    name: "distributionIndex",
    meta: {
      title: "分销员管理",
      icon: ""
    }
  }, {
    path: "distributionconfig",
    component: function component() {
      return import("@/views/distribution/config/index");
    },
    name: "distributionConfig",
    meta: {
      title: "分销配置",
      icon: ""
    }
  }, {
    path: "jackpot",
    component: function component() {
      return import("@/views/distribution/jackpot/index");
    },
    name: "Jackpot",
    meta: {
      title: "奖池",
      icon: ""
    },
    children: [{
      path: "bonus",
      component: function component() {
        return import("@/views/distribution/jackpot/bonus/index");
      },
      name: "bonus",
      meta: {
        title: "分红",
        icon: ""
      }
    }, {
      path: "envelope",
      component: function component() {
        return import("@/views/distribution/jackpot/envelope/index");
      },
      name: "envelope",
      meta: {
        title: "红包",
        icon: ""
      }
    }]
  }, {
    path: "order",
    component: function component() {
      return import("@/views/distribution/order/index");
    },
    name: "distributionOrder",
    meta: {
      title: "分销订单",
      icon: ""
    }
  }]
};
export default distributionRouter;