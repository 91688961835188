export default {
  shortcuts: [{
    text: "今天",
    onClick: function onClick(picker) {
      picker.$emit("pick", [new Date(), new Date()]);
    }
  }, {
    text: "昨天",
    onClick: function onClick(picker) {
      var date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24);
      picker.$emit("pick", [date, date]);
    }
  }, {
    text: "最近一周",
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      end.setTime(start.getTime() - 3600 * 1000 * 24);
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit("pick", [start, end]);
    }
  }, {
    text: "最近一个月",
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      end.setTime(start.getTime() - 3600 * 1000 * 24);
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit("pick", [start, end]);
    }
  }, {
    text: "最近三个月",
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      end.setTime(start.getTime() - 3600 * 1000 * 24);
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit("pick", [start, end]);
    }
  }, {
    text: "最近半年",
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      end.setTime(start.getTime() - 3600 * 1000 * 24);
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
      picker.$emit("pick", [start, end]);
    }
  }, {
    text: "最近一年",
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      end.setTime(start.getTime() - 3600 * 1000 * 24);
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
      picker.$emit("pick", [start, end]);
    }
  }, {
    text: "本周",
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      end.setTime(start.getTime() - 3600 * 1000 * 24);
      //现在星期几；0代表星期天，6代表星期六
      var thisDay = start.getDay();
      //现在是一个月的第几天
      var thisDate = start.getDate();
      console.log(thisDay);
      console.log(thisDate);
      if (thisDay != 0) {
        start.setDate(thisDate - thisDay + 1);
      }
      picker.$emit("pick", [start, end]);
    }
  }, {
    text: "本月",
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      end.setTime(start.getTime() - 3600 * 1000 * 24);
      start.setDate(1);
      picker.$emit("pick", [start, end]);
    }
  }, {
    text: "本季度",
    onClick: function onClick(picker) {
      var oDate = new Date();
      var thisYear = oDate.getFullYear();
      var thisMonth = oDate.getMonth() + 1;
      var n = Math.ceil(thisMonth / 3); // 季度
      var Month = n * 3 - 1;
      var start = new Date(thisYear, Month - 2, 1);
      var end = new Date();
      end.setTime(end.getTime() - 3600 * 1000 * 24);
      picker.$emit("pick", [start, end]);
    }
  }, {
    text: "本年",
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setMonth(0);
      start.setDate(1);
      end.setTime(end.getTime() - 3600 * 1000 * 24);
      picker.$emit("pick", [start, end]);
    }
  }]
};