import Layout from "@/layout";
var agencyRouter = {
  path: "/agency",
  component: Layout,
  redirect: "/agency/index",
  name: "agency",
  meta: {
    title: "机构",
    icon: "clipboard"
  },
  children: [{
    path: "agent",
    component: function component() {
      return import("@/views/agency/index");
    },
    name: "agent",
    meta: {
      title: "代理商",
      icon: ""
    },
    children: [{
      path: "apply",
      component: function component() {
        return import("@/views/agency/agent/applyList");
      },
      name: "apply",
      meta: {
        title: "代理商列表",
        icon: ""
      }
    }, {
      path: "conf",
      component: function component() {
        return import("@/views/agency/agent/conf");
      },
      name: "conf",
      meta: {
        title: "代理商配置",
        icon: ""
      }
    }]
  }, {
    path: "supplier",
    component: function component() {
      return import("@/views/agency/index");
    },
    name: "supplier",
    meta: {
      title: "供应商",
      icon: ""
    },
    children: [{
      path: "list",
      component: function component() {
        return import("@/views/agency/supplier/list");
      },
      name: "list",
      meta: {
        title: "供应商",
        icon: ""
      }
    }]
  }]
};
export default agencyRouter;